/**
 * ----------------------------------------------------------------------------
 * Custom Fonts
 * ----------------------------------------------------------------------------
*/
@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-Thin.woff?v=3766461211073421937") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-ThinItalic.woff?v=16103398989973003738") format("woff");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-ExtraLight.woff?v=8210175839024396346") format("woff");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-ExtraLightItalic.woff?v=13067807440831840246") format("woff");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-Light.woff?v=4176873359718210001") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-LightItalic.woff?v=15679597717807363240") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-Book.woff?v=10732234486844662267") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-BookItalic.woff?v=2766438930906356804") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-Medium.woff?v=17652947614716537803") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-MediumItalic.woff?v=2826172324244890496") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-SemiBold.woff?v=14897667451968528383") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-SemiBoldItalic.woff?v=14080685255879321911") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-Bold.woff?v=18144872287465946919") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-BoldItalic.woff?v=2456145863993293445") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-ExtraBold.woff?v=9171876904969153758") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-ExtraBoldItalic.woff?v=16691588028780646229") format("woff");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-Black.woff?v=17061816095717766769") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Campton";
  src: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/Campton-BlackItalic.woff?v=5334891128726508203") format("woff");
  font-weight: 900;
  font-style: italic; }

/**
 * ----------------------------------------------------------------------------
 * Global text styles
 * ----------------------------------------------------------------------------
*/
.heading-desktop-large {
  font-size: 64px;
  line-height: 66px; }
  @media screen and (min-width: 641px) {
    .heading-desktop-large {
      font-size: 40px;
      line-height: 42px; } }
  @media screen and (min-width: 1440px) {
    .heading-desktop-large {
      font-size: 48px;
      line-height: 50px; } }
  @media screen and (min-width: 1680px) {
    .heading-desktop-large {
      font-size: 56px;
      line-height: 58px; } }
  @media screen and (min-width: 1920px) {
    .heading-desktop-large {
      font-size: 64px;
      line-height: 66px; } }

.heading-desktop-medium {
  font-size: 48px;
  line-height: 50px; }
  @media screen and (min-width: 641px) {
    .heading-desktop-medium {
      font-size: 30px;
      line-height: 32px; } }
  @media screen and (min-width: 1440px) {
    .heading-desktop-medium {
      font-size: 36px;
      line-height: 38px; } }
  @media screen and (min-width: 1680px) {
    .heading-desktop-medium {
      font-size: 42px;
      line-height: 50px; } }
  @media screen and (min-width: 1920px) {
    .heading-desktop-medium {
      font-size: 48px;
      line-height: 50px; } }

.heading-desktop-small {
  font-size: 32px;
  line-height: 34px; }
  @media screen and (min-width: 641px) {
    .heading-desktop-small {
      font-size: 20px;
      line-height: 22px; } }
  @media screen and (min-width: 1440px) {
    .heading-desktop-small {
      font-size: 24px;
      line-height: 26px; } }
  @media screen and (min-width: 1680px) {
    .heading-desktop-small {
      font-size: 28px;
      line-height: 30px; } }
  @media screen and (min-width: 1920px) {
    .heading-desktop-small {
      font-size: 32px;
      line-height: 34px; } }

.heading-desktop-large-bold {
  font-family: 'Campton-Bold';
  font-size: 64px;
  line-height: 66px; }
  @media screen and (min-width: 641px) {
    .heading-desktop-large-bold {
      font-size: 40px;
      line-height: 42px; } }
  @media screen and (min-width: 1440px) {
    .heading-desktop-large-bold {
      font-size: 48px;
      line-height: 50px; } }
  @media screen and (min-width: 1680px) {
    .heading-desktop-large-bold {
      font-size: 56px;
      line-height: 58px; } }
  @media screen and (min-width: 1920px) {
    .heading-desktop-large-bold {
      font-size: 64px;
      line-height: 66px; } }

.heading-desktop-medium-bold {
  font-family: 'Campton-Bold';
  font-size: 48px;
  line-height: 50px; }
  @media screen and (min-width: 641px) {
    .heading-desktop-medium-bold {
      font-size: 30px;
      line-height: 32px; } }
  @media screen and (min-width: 1440px) {
    .heading-desktop-medium-bold {
      font-size: 36px;
      line-height: 38px; } }
  @media screen and (min-width: 1680px) {
    .heading-desktop-medium-bold {
      font-size: 42px;
      line-height: 50px; } }
  @media screen and (min-width: 1920px) {
    .heading-desktop-medium-bold {
      font-size: 48px;
      line-height: 50px; } }

.heading-desktop-small-bold {
  font-family: 'Campton-Bold';
  font-size: 32px;
  line-height: 34px; }
  @media screen and (min-width: 641px) {
    .heading-desktop-small-bold {
      font-size: 20px;
      line-height: 22px; } }
  @media screen and (min-width: 1440px) {
    .heading-desktop-small-bold {
      font-size: 24px;
      line-height: 26px; } }
  @media screen and (min-width: 1680px) {
    .heading-desktop-small-bold {
      font-size: 28px;
      line-height: 30px; } }
  @media screen and (min-width: 1920px) {
    .heading-desktop-small-bold {
      font-size: 32px;
      line-height: 34px; } }

.text-desktop-large {
  font-size: 24px;
  line-height: 38px;
  letter-spacing: 0.75px; }
  @media screen and (min-width: 641px) {
    .text-desktop-large {
      font-size: 15px;
      line-height: 24px; } }
  @media screen and (min-width: 1440px) {
    .text-desktop-large {
      font-size: 18px;
      line-height: 29px; } }
  @media screen and (min-width: 1680px) {
    .text-desktop-large {
      font-size: 21px;
      line-height: 34px; } }
  @media screen and (min-width: 1920px) {
    .text-desktop-large {
      font-size: 24px;
      line-height: 38px; } }

.text-desktop-medium {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0.75px; }
  @media screen and (min-width: 641px) {
    .text-desktop-medium {
      font-size: 12px;
      line-height: 21px; } }
  @media screen and (min-width: 1440px) {
    .text-desktop-medium {
      font-size: 14px;
      line-height: 26px; } }
  @media screen and (min-width: 1680px) {
    .text-desktop-medium {
      font-size: 16px;
      line-height: 30px; } }
  @media screen and (min-width: 1920px) {
    .text-desktop-medium {
      font-size: 18px;
      line-height: 34px; } }

.text-desktop-small {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.75px; }
  @media screen and (min-width: 641px) {
    .text-desktop-small {
      font-size: 10px;
      line-height: 18px; } }
  @media screen and (min-width: 1440px) {
    .text-desktop-small {
      font-size: 12px;
      line-height: 21px; } }
  @media screen and (min-width: 1680px) {
    .text-desktop-small {
      font-size: 14px;
      line-height: 24px; } }
  @media screen and (min-width: 1920px) {
    .text-desktop-small {
      font-size: 16px;
      line-height: 28px; } }

.text-desktop-xsmall {
  font-family: 'Campton-Bold';
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.75px; }
  @media screen and (min-width: 641px) {
    .text-desktop-xsmall {
      font-size: 10px;
      line-height: 14px; } }
  @media screen and (min-width: 1440px) {
    .text-desktop-xsmall {
      font-size: 11px;
      line-height: 18px; } }
  @media screen and (min-width: 1680px) {
    .text-desktop-xsmall {
      font-size: 12px;
      line-height: 21px; } }
  @media screen and (min-width: 1920px) {
    .text-desktop-xsmall {
      font-size: 14px;
      line-height: 24px; } }
